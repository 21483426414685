#cookie-bar {
    background: #252525;
    line-height: 30px;
    color: #eeeeee;
    text-align: center;
    padding: 3px 0;
    position: fixed;
    bottom: 0px;
    width: 100%;
    top: auto;
    left: 0;
    z-index: 999;
    padding-top: 8px;
    padding-bottom: 8px;
}

#cookie-bar p {
    margin: 0;
    padding: 0;
}

#cookie-bar a {
    color: #ffffff;
    display: inline-block;
    border-radius: 3px;
    text-decoration: none;
    padding: 0 6px;
    margin-left: 8px;
}


#cookie-bar .cb-enable {
  color: #fff;
  background-color: #2ab27b;
  border-color: #259d6d;
}

#cookie-bar .cb-enable:focus {
  color: #fff;
  background-color: #20895e;
  border-color: #0d3625;
}

#cookie-bar .cb-enable:hover {
  color: #fff;
  background-color: #20895e;
  border-color: #196c4b;
}

#cookie-bar .cb-disable {
    background: #990000;
}

#cookie-bar .cb-disable:hover {
    background: #bb0000;
}

#cookie-bar .cb-policy {
    background: #0033bb;
}

#cookie-bar .cb-policy:hover {
    background: #0055dd;
}