/*!
 * Start Bootstrap - Agency v3.3.7+1 (http://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
body {
  overflow-x: hidden;
  font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-muted {
  color: #777777;
}
.text-primary {
  color: #C22B53;
}
p {
  font-size: 14px;
  line-height: 1.75;
}
p.large {
  font-size: 16px;
}
a,
a:hover,
a:focus,
a:active,
a.active {
  outline: none;
}
a,
a.btn-link {
  color: #C22B53;
}
a:hover,
a:focus,
a:active,
a.active,
a.btn-link:hover,
a.btn-link:focus,
a.btn-link:active,
a.btn-link.active {
  color: #a52245;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
#logo-nav{
    height: 100%;
    max-height: 50px;
}
.img-centered {
  margin: 0 auto;
}
.bg-light-gray {
  background-color: #ffffff;
}
.bg-darkest-gray {
  background-color: #222222;
}
.btn-primary {
  color: white;
  background-color: #C22B53;
  border-color: #C22B53;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #a52245;
  border-color: #5A1C38;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #C22B53;
  border-color: #C22B53;
}
.btn-primary .badge {
  color: #C22B53;
  background-color: white;
}
.btn-xl {
  color: white;
  background-color: #C22B53;
  border-color: #C22B53;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  padding: 20px 40px;
}
.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  color: white;
  background-color: #a52245;
  border-color: #5A1C38;
}
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
}
.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  background-color: #C22B53;
  border-color: #C22B53;
}
.btn-xl .badge {
  color: #C22B53;
  background-color: white;
}
.navbar-custom li.divider {
    border-right: 2px solid #C22B53;
    line-height: 48px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 0px white;
}
.affix.navbar-custom li.divider {
    border-right: 2px solid white;
}
.navbar-custom {
    background-color: rgba( 255, 255, 255, 0.5 );
    border-color: transparent;
    text-shadow: 1px 1px 3px #ffffff;
}
.navbar-custom .navbar-brand {
  color: #C22B53;
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
}
.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus,
.navbar-custom .navbar-brand:active,
.navbar-custom .navbar-brand.active {
  color: #a52245;
}
.navbar-custom .navbar-collapse {
  border-color: rgba(255, 255, 255, 0.02);
}

@media (max-width: 768px) {
    .navbar-custom .navbar-collapse {
      border-color: rgba(255, 255, 255, 0.05);
    }
}

.navbar-custom .navbar-toggle {
  background-color: #C22B53;
  border-color: #C22B53;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
}
.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #C22B53;
}
.navbar-custom .nav li a {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #C22B53;
  margin-left: 5px;
  margin-right: 5px;
}
.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus {
  color: #C22B53;
  outline: none;
}
.navbar-custom .navbar-nav > .active > a {
  border-radius: 0;
  color: white;
  background-color: #C22B53;
  text-shadow: 0px 0px 0px;
}
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus {
  color: white;
  background-color: #a52245;
  text-shadow: 0px 0px 0px;
}
.navbar-custom .navbar-nav > li > a:hover,
.navbar-custom .navbar-nav > li > a:focus {
  color: white;
  background-color: #a52245;
  border-radius: 3px;
  text-shadow: 0px 0px 0px;
}

.affix.navbar-custom li > a {
    color: white;
    text-shadow: 1px 1px 3px #000000;
}
@media (min-width: 768px) {
  .navbar-custom {
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    border: none;
  }
  .navbar-custom .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .navbar-custom .navbar-nav > .active > a {
    border-radius: 3px;
  }
}
@media (min-width: 768px) {
  .navbar-custom.affix {
    background-image: url( ../images/presentation/header-nav-bg-1.jpg );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10px 0;
  }
  .navbar-custom.affix .navbar-brand {
    font-size: 1.5em;
  }
}
@media (max-width: 768px) {
  .navbar-custom.affix {
    background-image: url( ../images/presentation/header-nav-bg-1.jpg );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
@media (max-width: 1300px) {
    .nav > li > a {
        font-size: 12px;
    }
    .navbar-custom .nav li a {
        margin-left: 2px;
        margin-right: 2px;
    }
}
@media (max-width: 1150px) {
    .nav > li > a {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 1030px) {
    .navbar-custom .nav .divider{
        display:none;
    }
}
.navbar-custom .dropdown-menu a{
    color: #222222 !important;
}
.navbar-brand{
    padding-top:0px;
    padding-bottom:0px;
}

.navbar-custom .dropdown-menu a{
    font-weight: normal;
    text-shadow: none !important;
}

.navbar-custom .dropdown-menu a:hover,
.navbar-custom .dropdown-menu a:focus{
    background-color: transparent !important;
}


.navbar-custom .dropdown-menu li:hover,
.navbar-custom .dropdown-menu li:focus{
    background-color: lightgrey !important;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: #a52245;
    border-color: #a52245;
    color: white;
}
header {
  background-image: url('../images/presentation/header-bg-1_rev.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
}
header .intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
}
header .intro-text .intro-lead-in {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 25px;
  text-shadow: 2px 2px 2px black;
}
header .intro-text .intro-heading {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 25px;
  text-shadow: 2px 2px 2px black;
}
@media (min-width: 768px) {
  header .intro-text {
    padding-top: 300px;
    padding-bottom: 200px;
  }
  header .intro-text .intro-lead-in {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  header .intro-text .intro-heading {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 75px;
    line-height: 75px;
    margin-bottom: 50px;
  }
}
section {
  padding: 100px 0;
}
section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}
section h3.section-subheading {
  font-size: 16px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
    line-height: 22px;
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}
.service-heading {
  margin: 15px 0;
  text-transform: none;
}
#design .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}
#design .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
#design .portfolio-item .portfolio-link .portfolio-hover {
  background: rgba(194, 43, 83, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}
#design .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}
#design .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}
#design .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
  margin-top: -12px;
}
#design .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#design .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
  margin: 0;
}
#design .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  padding: 25px;
}
#design .portfolio-item .portfolio-caption h4 {
  text-transform: none;
  margin: 0;
}
#design .portfolio-item .portfolio-caption p {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
  margin: 0;
}
#design * {
  z-index: 2;
}
@media (min-width: 767px) {
  #design .portfolio-item {
    margin: 0 0 30px;
  }
}
.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: "";
  width: 2px;
  background-color: #f1f1f1;
  left: 40px;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 50px;
  position: relative;
  min-height: 50px;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  width: 100%;
  float: right;
  padding: 0 20px 0 100px;
  position: relative;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline > li .timeline-image {
  left: 0;
  margin-left: 0;
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 100;
  background-color: #C22B53;
  color: white;
  border-radius: 100%;
  border: 7px solid #f1f1f1;
  text-align: center;
}
.timeline > li .timeline-image h4 {
  font-size: 10px;
  margin-top: 12px;
  line-height: 14px;
}
.timeline > li .timeline-image h4 a {
  color: white;
}
.timeline > li .timeline-image h4 a:focus,
.timeline > li .timeline-image h4 a:hover {
  color: white;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  text-align: left;
  padding: 0 20px 0 100px;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading {
  text-transform: none;
    font-style: italic;
    font-weight: normal;
}
.timeline .timeline-body > p,
.timeline .timeline-body > ul {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    margin-bottom: 100px;
    min-height: 100px;
  }
  .timeline > li .timeline-panel {
    width: 41%;
    float: left;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    width: 100px;
    height: 100px;
    left: 50%;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 13px;
    margin-top: 16px;
    line-height: 18px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    text-align: left;
    padding: 0 30px 20px 20px;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    margin-top: 30px;
    line-height: 26px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}
.team-member {
  text-align: center;
  margin-bottom: 50px;
}
.team-member img {
  margin: 0 auto;
  border: 7px solid white;
}
.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}
.team-member p {
  margin-top: 0;
}
aside.clients img {
  margin: 50px auto;
}
section#contact {
  background-position: center;
  background-repeat: no-repeat;
}
section#contact .section-heading {
  color: white;
}
section#contact .form-group {
  margin-bottom: 25px;
}
section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;
}
section#contact .form-group input.form-control {
  height: auto;
}
section#contact .form-group textarea.form-control {
  height: 236px;
}
section#contact .form-control:focus {
  border-color: #C22B53;
  box-shadow: none;
}
section#contact ::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #eeeeee;
}
section#contact :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #eeeeee;
}
section#contact ::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #eeeeee;
}
section#contact :-ms-input-placeholder {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #eeeeee;
}
section#contact .text-danger {
  color: #e74c3c;
}
footer {
  padding: 25px 0;
  text-align: center;
}

footer.even{
  background-color: white;
}

footer span.copyright {
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}
footer ul.quicklinks {
  margin-bottom: 0;
  line-height: 40px;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-transform: none;
}
ul.social-buttons {
  margin-bottom: 0;
}
ul.social-buttons li a {
  display: block;
  background-color: #222222;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  color: white;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #C22B53;
}
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: none;
}
.portfolio-modal .modal-dialog {
  margin: 0;
  height: 100%;
  width: auto;
}
.portfolio-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 100px 0;
  text-align: center;
}
.portfolio-modal .modal-content h2 {
  margin-bottom: 15px;
  font-size: 3em;
}
.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}
.portfolio-modal .modal-content p.item-intro {
  margin: 20px 0 30px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 16px;
}
.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 30px;
  margin-top: 0;
}
.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}
.portfolio-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}
.portfolio-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #222222;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.portfolio-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #222222;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}
.portfolio-modal .modal-backdrop {
  opacity: 0;
  display: none;
}
img::selection {
  background: transparent;
}
img::-moz-selection {
  background: transparent;
}
body {
  webkit-tap-highlight-color: #C22B53;
}

.container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

.tab-content.well {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.timeline-image span.glyphicons {
    font-size: 80px;
}


/**
 * Layout handle
 ***/

#layout_content{
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    margin-left: 10px;
}

#layout_content > div:first-child{
    padding-bottom: 30px;
}

#layout_content > div:last-child{
    padding-top: 30px;
}

#layout_content #content-place,
#layout_content #content-agency{
    background-color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}

#layout_content #place-name,
#layout_content #agency-name{
    color: #C22B53;
}

#layout_content #place-description,
#layout_content #agency-description,
#layout_content #place-logo,
#layout_content #agency-logo,
#layout_content #place-image{
    padding-top: 10px;
    padding-bottom: 15px;
}

#layout_content #place-logo img,
#layout_content #place-image img,
#layout_content #agency-logo img{
    width: 100%;
}

/*# sourceMappingURL=presentation.css.map */
